import "@fontsource/pt-sans/latin-400.css";
import "./style.css";
import translations from "./translations";
import { Language, Occupancy } from "./types";

const params = new URLSearchParams(location.search);

const parseDate = (date: string | null): Date | undefined => {
  if (!date) {
    return undefined;
  }

  const parsedDate = new Date(date);

  try {
    if (Number.isNaN(parsedDate.getTime())) {
      throw new ValidationError("Invalid date Format");
    }

    return parsedDate;
  } catch (err) {
    if (err instanceof ValidationError) {
      console.warn(`Invalid date value: ${err.message}`);
      return undefined;
    }

    throw err;
  }
};

const checkIsNumberArray = (array: unknown[]): array is number[][] =>
  array.flat(1).every((n) => typeof n === "number");

const parseGuests = (guests: string | null): number[][] | undefined => {
  try {
    if (!guests) {
      throw new ValidationError("Invalid value for guests. Guests is null");
    }
    const parsedGuests = JSON.parse(guests);
    if (!Array.isArray(parsedGuests)) {
      throw new ValidationError("Invalid guest is not an array");
    }

    if (!parsedGuests.length) {
      throw new ValidationError("Empty guest array");
    }

    if (!checkIsNumberArray(parsedGuests)) {
      throw new ValidationError("Invalid room guests is not an array");
    }

    parsedGuests.forEach((roomGuests) => {
      roomGuests.forEach((age) => {
        if (age < 0) {
          throw new ValidationError("Age is below 0");
        }

        if (age > 18) {
          throw new ValidationError("Child age must be lower than 18");
        }

        if (Math.floor(age) !== age) {
          throw new ValidationError("Child age must be an integer");
        }
      });

      if (!roomGuests.includes(18)) {
        throw new ValidationError("Room guests must contain one adult");
      }
    });

    return parsedGuests;
  } catch (err) {
    if (err instanceof ValidationError) {
      console.warn(`Invalid value for guests: ${err.message}`);
      return undefined;
    }
    throw err;
  }
};

class ValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ValidationError";
  }
}

const getOccupancy = (guests: number[][]): Occupancy => ({
  adults: guests.flat().filter((age) => age === 18).length,
  children: guests.flat().filter((age) => age < 18),
});

const checkIsLanguage = (value: string): value is Language =>
  Object.values(Language).includes(<Language>value);

const currentLanguage = checkIsLanguage(document.documentElement.lang)
  ? document.documentElement.lang
  : Language.English;

const guests = parseGuests(params.get("guests"));

const adult = document.querySelector<HTMLElement>(".js-adults");

if (guests) {
  const occupancy = getOccupancy(guests);
  if (adult) {
    adult.innerHTML = translations[currentLanguage].occupancyAdults(
      occupancy.adults,
    );
  }

  const children = document.querySelector<HTMLElement>(".js-children");
  if (children) {
    children.innerHTML =
      translations[currentLanguage].occupancyChildren(occupancy.children) +
      (occupancy.children.length > 0
        ? translations[currentLanguage].occupancyChildrenAges(
            occupancy.children,
          )
        : "");
  }
}

const renderDate = (date: Date, element: HTMLElement) => {
  const timeElement = document.createElement("time");
  const isoDate = date.toISOString().split("T")[0];
  if (isoDate) timeElement.dateTime = isoDate;
  timeElement.textContent = date.toLocaleDateString(
    currentLanguage === "en" ? `${currentLanguage}-GB` : currentLanguage,
    {
      weekday: "short",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    },
  );

  element.appendChild(timeElement);
};

const showTravelDates = () => {
  const arrivalDate = parseDate(params.get("arrival"));
  const departureDate = parseDate(params.get("departure"));

  const arrival = document.querySelector<HTMLElement>(".js-date-checkin");
  const departure = document.querySelector<HTMLElement>(".js-date-checkout");

  try {
    if (!arrival || !departure) {
      throw Error("Arrival and departure elements not found");
    }
    if (!arrivalDate || !departureDate) {
      throw new ValidationError("Arrival or departure date is not set");
    }
    if (arrivalDate >= departureDate) {
      throw new ValidationError(
        "Invalid date values: Departure is before arrival",
      );
    }
  } catch (err) {
    if (err instanceof ValidationError) {
      console.warn(`Invalid value for guests: ${err.message}`);
      return undefined;
    }
    throw err;
  }
  renderDate(arrivalDate, arrival);
  renderDate(departureDate, departure);
};

showTravelDates();

const propertyId = params.get("propertyId");
const widgetContainer = document.querySelector<HTMLElement>("#Bs-PortalWidget");

if (!propertyId && widgetContainer) {
  widgetContainer.classList.add("portalwidget-not-found");
  widgetContainer.innerHTML =
    translations[currentLanguage].noAccommodationFound;

  console.warn("Missing propertyId parameter");
} else {
  window.BookingSüdtirolTrackingConsent = false;
  document
    .querySelector("#booking-suedtirol-js")
    ?.addEventListener("load", () => {
      if (!window.BookingSüdtirol?.Widgets?.Portal) {
        return;
      }

      window.BookingSüdtirol.Widgets.Portal("#Bs-PortalWidget", {
        id: "2fc9df14-23e8-47b1-83a7-e79df3287048",
        lang: currentLanguage,
        privacyURL: `https://www.bookingsuedtirol.com/${currentLanguage}/datenschutz`,
        termsURL: `https://www.bookingsuedtirol.com/${currentLanguage}/agb`,
        autoScroll: false,
      });
    });
}

const dialogBtn = document.querySelector(".js-credits-button");
const dialog = document.querySelector("dialog");
const closeBtn = document.querySelector(".js-btn-close");

dialogBtn?.addEventListener("click", () => {
  dialog?.showModal();
});

closeBtn?.addEventListener("click", () => {
  dialog?.close();
});

document
  .querySelector<HTMLElement>(".back-to-offer-link")
  ?.addEventListener("click", () => {
    history.back();
  });
